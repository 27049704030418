import React from 'react'

import facebook from '../img/social/facebook-logo.svg'
import linkedin from '../img/social/linkedin-logo.svg'
import google from '../img/social/google-logo.svg'

const Social = class extends React.Component {





    render() {
      return (
        <div id="soc">
        <div className="socialicons" style={{width:'30px'}}>
        <a id="fb"  style={{padding:'5px',margin:'0px'}} href="https://www.facebook.com/emulticontent.ecommerce"
        target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" alt="facebook" width="25px" height="25px" src={facebook}/></a>
        <a id="google" style={{padding:'5px',margin:'0px'}} href="https://www.google.com/search?q=e-multicontent"
         target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" alt="twitter"  width="25px" height="25px" src={google}/></a>
        <a id="linked" style={{padding:'5px',margin:'0px'}} href="https://www.linkedin.com/showcase/multicontent"
         target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" alt="linkedin" width="25px" height="25px" src={linkedin} /></a>
         </div>
        </div>
      )
    }



}




export default Social
