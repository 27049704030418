/* eslint-disable */
export const TrimStr = (x) => (
  x.normalize("NFD")
  // remove accents - diacritics - not effective for Polish letter ł
  .replace(/[\u0300-\u036f]/g, "")
  // letter ł
  .replace(/['ł']/g, "l")
  // change whitespace to -
  .replace(/\s+/g, "-")
  // remove special chars except for -
  .replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '')
  // remove emoji
  .replace(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g, '')
  .toLowerCase()
);
