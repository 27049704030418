import React, { useEffect, useState, useMemo, useCallback } from "react"
import { FloatingBanner } from "./FloatingBanner"
import Cookies from "universal-cookie"
import PropTypes from "prop-types"

function ConsentForm({ color }) {
    const [decisionMade, setDecisionMade] = useState(true) // start with true to avoid flashing
    const cookies = useMemo(() => new Cookies(null, { path: '/' }), []);

    function gtag() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(arguments);
    }

    const sendConsent = useCallback((consent) => {
        gtag('consent', 'default', consent);
    }, []);

    useEffect(() => {
        if (cookies.get("cookie_v2") !== undefined) {
            setDecisionMade(true)
        } else {
            setDecisionMade(false)
        }
    }, [cookies, setDecisionMade, sendConsent])

    const handleDecision = (outcome) => {
        const consent = {
            'ad_storage': outcome,
            'analytics_storage': outcome,
            'ad_user_data': outcome,
            'ad_personalization': outcome,
            'functionality_storage': outcome,
            'personalization_storage': outcome,
            'security_storage': outcome,
        }

        cookies.set("cookie_v2", consent, {
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            path: "/",
            //domain: "e-multicontent.pl",
        })
        //console.log(cookies.cookies.cookie_v2)
        sendConsent(consent)
        setDecisionMade(true)
    }

    return (
        decisionMade ? (
            <>
            <button role="button" aria-label="cookie-consent" className="btnblog is-size-4"
            onClick={
                () => {
                    setDecisionMade(false);
                }
            }
            style={{position:'fixed',bottom:'0',left:'0'}}>
            <span role="img" aria-label="cookie-emoji">🍪</span>
            </button>
            </>
        ) : (
            <FloatingBanner
                color={color}
                header='<span role="img" aria-label="cookie-emoji">🍪</span>&nbsp; Zgoda na pliki cookies'
                message={`Informujemy że, <b style="color:#36b3d2">e</b>-multicontent.pl wykorzystuje pliki cookies
                w celu prawidłowego działania naszej witryny, a także w celach analitycznych i reklamowych.
                <br><br>
                Więcej o tym, jak wykorzystujemy pliki cookies, możesz dowiedzieć się w naszej <a href='/polityka-prywatnosci/#cookies'>Polityce Prywatności</a>.`}
                acceptText="Wyrażam<br> zgodę"
                denyText="Nie wyrażam<br> zgody"
                onAccept={
                    () => {
                        handleDecision("granted")
                    }
                }
                onDeny={
                    () => {
                        handleDecision("denied")
                    }
                } />
        )
    )
}

ConsentForm.propTypes = {
    color: PropTypes.string.isRequired,
}

ConsentForm.defaultProps = {
    color: "#111111",
}

export default ConsentForm;
