import React from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import Navbar from '../components/Navbar'
import './all.sass'
import { withPrefix } from 'gatsby'
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/800.css"

import ConsentForm  from '../components/cookies/ConsentForm'

const Footer = loadable(() => import('../components/Footer'));


const TemplateWrapper = ({ children }) => {


  return (
    <div>
      <Helmet>
        <html lang="pl" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "e-multicontent",
            "url": "https://e-multicontent.pl",
            "logo": "https://e-multicontent.pl/logo-e-multicontent.svg",
            "alternateName": "e-multicontent",
            "sameAs": [
              "https://www.facebook.com/emulticontent.ecommerce/",
              "https://www.linkedin.com/showcase/multicontent/",
              "https://g.page/e-multicontent/"
            ],
            "contactPoint": [
              {
                "@type": "ContactPoint",
                "telephone": "+48717591140",
                "contactType": "customer service",
                "email": "biuro@e-multicontent.pl",
                "availableLanguage": ["en","pl"]
              }
            ],
            "address":[{
                "@type":"PostalAddress",
                "streetAddress":"Ślężna 148",
                "addressLocality":"Wrocław",
                "postalCode":"55-010",
                "addressCountry":"PL"
            }]
          })}
          </script>

      </Helmet>
      <Navbar />
      <div>{children}</div>

      {/*
        Consent mode v2 IMPLEMENTATION OF GDPR COOKIE NOTICE */}
      <ConsentForm />




      <Footer />
    </div>
  )
}

export default TemplateWrapper
